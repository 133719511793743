// extracted by mini-css-extract-plugin
export var bottomflex_inner = "career-module--bottomflex_inner--GUbce";
export var bottomflex_wrapper = "career-module--bottomflex_wrapper--2dktB";
export var career_banner = "career-module--career_banner--Ihd9D";
export var career_banner_bg_txt = "career-module--career_banner_bg_txt--6-XqE";
export var career_banner_btn = "career-module--career_banner_btn--BvLRK";
export var career_banner_content = "career-module--career_banner_content--86ABo";
export var career_banner_content_header = "career-module--career_banner_content_header--z2bZV";
export var career_banner_content_text = "career-module--career_banner_content_text--a08E+";
export var career_content_inner = "career-module--career_content_inner--NWmJp";
export var career_content_section2_inner = "career-module--career_content_section2_inner--ODhLs";
export var career_content_section2_inner_wrapper = "career-module--career_content_section2_inner_wrapper--8Zjeo";
export var career_content_section2_wrapper = "career-module--career_content_section2_wrapper--2W3PS";
export var career_content_section_wrapper = "career-module--career_content_section_wrapper--RWx8U";
export var career_image_text = "career-module--career_image_text--UiS-c";
export var career_image_wrapper = "career-module--career_image_wrapper--3-nEw";
export var career_inner_contents = "career-module--career_inner_contents--ZIWIr";
export var career_inner_contents_header = "career-module--career_inner_contents_header--O9Jqo";
export var career_inner_contents_wrapper = "career-module--career_inner_contents_wrapper--HHeEs";
export var career_inner_img = "career-module--career_inner_img--woXP7";
export var career_offer_content = "career-module--career_offer_content--YtCRr";
export var career_offer_content2 = "career-module--career_offer_content2--OTjCq";
export var career_offer_content_inner = "career-module--career_offer_content_inner--xF+qW";
export var career_offer_content_inner2 = "career-module--career_offer_content_inner2--VtKaM";
export var career_offer_wrapper = "career-module--career_offer_wrapper--L5KR-";
export var career_open_positions__link = "career-module--career_open_positions__link--h5wuq";
export var career_open_positions_content = "career-module--career_open_positions_content--6eBtN";
export var career_open_positions_inner = "career-module--career_open_positions_inner--NX5cB";
export var career_open_positions_wrapper = "career-module--career_open_positions_wrapper--nMyN8";
export var career_section_flex1 = "career-module--career_section_flex1--Ksjg2";
export var career_wrapper = "career-module--career_wrapper--p9qWm";
export var earth = "career-module--earth--NHCRi";
export var inner_flex = "career-module--inner_flex--ws72+";
export var suggestion = "career-module--suggestion--9XZF7";